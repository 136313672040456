import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import numeral from "numeral";
import { useRecoilState, useRecoilValue } from "recoil";
import { hiveOsState } from "./recoil/rig/hiveos";
import { systemOsState } from "./recoil/system/os";
import {
  systemCpuState,
  systemCpuUsedState,
  systemCpuUsedChartsState,
  systemCpuTemperaturesState,
  systemCpuTemperaturesChartsState,
} from "./recoil/system/cpu";
import { systemBaseBoardState } from "./recoil/system/baseBoard";
import { systemGpuState, systemGpuTempState } from "./recoil/system/gpu";
import { systemMemState, systemMemLayoutState } from "./recoil/system/mem";
import { systemDiskState } from "./recoil/system/disk";
import { systemNetworkState } from "./recoil/system/network";
import {
  bitkubBalancesState,
  binanceBalancesState,
} from "./recoil/crypto/balances";
import { cryptoMarketState } from "./recoil/crypto/market";
import { cryptoRatesState } from "./recoil/crypto/rates";
import { darkModeState } from "./recoil/darkMode";
import { dateState, timeState } from "./recoil/datatime";
// import {
//   flexpoolBalanceState,
//   flexpoolWorkerCountState,
//   flexpoolStatsState,
//   flexpoolChartState,
//   flexpoolWorkersState,
// } from "./recoil/miner/flexpool";
import { nanopoolUserState, nanopoolChartState } from "./recoil/miner/nanopool";
import {
  ethermineDashboardState,
  etherminePayoutsState,
} from "./recoil/miner/ethermine";
import { verusState } from "./recoil/miner/verus";
import {
  duinocoinState,
  duinocoinStatisticState,
  duinocoinAccountValue,
} from "./recoil/miner/duinocoin";
import {
  Container,
  Tabs,
  Tab,
  Typography,
  Grid,
  Switch,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import WorkIcon from "@material-ui/icons/Work";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import Rig from "./pages/rig";
import Home from "./pages/home";
import System from "./pages/system";
import Coin from "./pages/coin";
import Miner from "./pages/miner";
import { scrollTop } from "./constants/scrollTop";
import moment from "moment";
import _ from "lodash";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
  wrapper: {
    paddingBottom: theme.spacing(10),
  },
  appBar: {
    position: "fixed",
    bottom: 0,
    width: "96%",
    left: "2%",
    backgroundColor: theme.palette.background.secondary,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
}));

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [, setHiveOs] = useRecoilState(hiveOsState);
  const [, setSystemOs] = useRecoilState(systemOsState);
  const [, setSystemCpu] = useRecoilState(systemCpuState);
  const [, setSystemBaseBoard] = useRecoilState(systemBaseBoardState);
  const [, setSystemCpuUsed] = useRecoilState(systemCpuUsedState);
  const [, setSystemCpuUsedCharts] = useRecoilState(systemCpuUsedChartsState);
  const [, setSystemCpuTemperatures] = useRecoilState(
    systemCpuTemperaturesState
  );
  const [, setSystemCpuTemperaturesCharts] = useRecoilState(
    systemCpuTemperaturesChartsState
  );
  const [, setSystemMem] = useRecoilState(systemMemState);
  const [, setSystemMemLayout] = useRecoilState(systemMemLayoutState);
  const [, setSystemGpu] = useRecoilState(systemGpuState);
  const [, setSystemGpuTemp] = useRecoilState(systemGpuTempState);
  const [, setSystemDisk] = useRecoilState(systemDiskState);
  const [, setSystemNetwork] = useRecoilState(systemNetworkState);
  const [, setBitkubBalances] = useRecoilState(bitkubBalancesState);
  const [, setBinanceBalances] = useRecoilState(binanceBalancesState);
  const [, setCryptoMarket] = useRecoilState(cryptoMarketState);
  const [, setCryptoRates] = useRecoilState(cryptoRatesState);
  const [, setMinerNanopoolUser] = useRecoilState(nanopoolUserState);
  // const [, setMinerFlexpoolBalance] = useRecoilState(flexpoolBalanceState);
  // const [, setMinerFlexpoolWorkerCount] = useRecoilState(
  //   flexpoolWorkerCountState
  // );
  // const [, setMinerFlexpoolStats] = useRecoilState(flexpoolStatsState);
  // const [, setMinerFlexpoolChart] = useRecoilState(flexpoolChartState);
  // const [, setMinerFlexpoolWorkers] = useRecoilState(flexpoolWorkersState);
  const [, setMinerEthermineDashboard] = useRecoilState(
    ethermineDashboardState
  );
  const [, setMinerEtherminePayouts] = useRecoilState(etherminePayoutsState);
  const [, setMinerNanopoolChart] = useRecoilState(nanopoolChartState);
  const [, setVerus] = useRecoilState(verusState);
  const [, setDuinocoin] = useRecoilState(duinocoinState);
  const [, setDuinocoinStatistic] = useRecoilState(duinocoinStatisticState);
  const duinocoinAccount = useRecoilValue(duinocoinAccountValue);
  const [darkMode, setDarkMode] = useRecoilState(darkModeState);
  const [, setDate] = useRecoilState(dateState);
  const [, setTime] = useRecoilState(timeState);

  // const cloudflareDev = () => {
  //   const headers = {
  //     "X-Auth-Email": process.env.REACT_APP_EMAIL,
  //     "X-Auth-Key": process.env.REACT_APP_AUTHORIZATION_KEY,
  //   };
  //   const purgeCache = axios({
  //     method: "post",
  //     url: `${process.env.REACT_APP_CLOUDFLARE_URL}/purge_cache`,
  //     data: {
  //       purge_everything: true,
  //     },
  //     headers,
  //   });
  //   const developmentMode = axios({
  //     method: "patch",
  //     url: `${process.env.REACT_APP_CLOUDFLARE_URL}/settings/development_mode`,
  //     data: {
  //       value: "on",
  //     },
  //     headers,
  //   });
  //   axios
  //     .all([purgeCache, developmentMode])
  //     .then(
  //       axios.spread((...responses) => {
  //         const resPurgeCache = responses[0];
  //         const resDevelopmentMode = responses[1];
  //         console.log(resPurgeCache);
  //         console.log(resDevelopmentMode);
  //       })
  //     )
  //     .catch((err) => console.log(err));
  // };

  const HiveOs = () => {
    axios
      .get(`${process.env.REACT_APP_API_PYTHON_URL}/hiveos/workers`)
      .then((res) => {
        setHiveOs(res.data);
      })
      .catch((err) => console.log(err));
  };

  const OsInfo = () => {
    axios
      .get(`${process.env.REACT_APP_API_NODE_URL}/os/info`)
      .then((res) => {
        setSystemOs(res.data);
      })
      .catch((err) => console.log(err));
  };

  const CpuInfo = () => {
    axios
      .get(`${process.env.REACT_APP_API_NODE_URL}/cpu/info`)
      .then((res) => {
        setSystemCpu(res.data);
      })
      .catch((err) => console.log(err));
  };

  const CpuTemperatures = () => {
    axios
      .get(`${process.env.REACT_APP_API_PYTHON_URL}/cpu/temperatures`)
      .then((res) => {
        let cpuTemperatures = res?.data?.cpu_thermal[0][1];
        setSystemCpuTemperatures(cpuTemperatures);
        setSystemCpuTemperaturesCharts((prevState) => [
          ...prevState,
          { temp: cpuTemperatures },
        ]);
      })
      .catch((err) => console.log(err));
  };

  const BaseBoard = () => {
    axios
      .get(`${process.env.REACT_APP_API_NODE_URL}/system/baseboard`)
      .then((res) => {
        setSystemBaseBoard(res.data);
      })
      .catch((err) => console.log(err));
  };

  const MemInfo = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_PYTHON_URL}/mem/info`)
      .then((res) => {
        setSystemMem(res.data);
      })
      .catch((err) => console.log(err));
  };

  const MemLayout = () => {
    axios
      .get(`${process.env.REACT_APP_API_NODE_URL}/memory/memLayout`)
      .then((res) => {
        setSystemMemLayout(res.data);
      })
      .catch((err) => console.log(err));
  };

  const CpuUsed = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_PYTHON_URL}/cpu/used`)
      .then((res) => {
        let cpuUsed = numeral(res.data).format("0,0");
        setSystemCpuUsed(cpuUsed);
        setSystemCpuUsedCharts((prevState) => [
          ...prevState,
          { cpuUesd: cpuUsed },
        ]);
      })
      .catch((err) => console.log(err));
  };

  const DiskInfo = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_PYTHON_URL}/disk/info`)
      .then((res) => {
        setSystemDisk(res.data);
      })
      .catch((err) => console.log(err));
  };

  const NetworkInfo = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_PYTHON_URL}/network/info`)
      .then((res) => {
        setSystemNetwork(res.data);
      })
      .catch((err) => console.log(err));
  };

  const GpuInfo = () => {
    axios
      .get(`${process.env.REACT_APP_API_NODE_URL}/graphics/info`)
      .then((res) => {
        setSystemGpu(res.data);
      })
      .catch((err) => console.log(err));
  };

  const GpuTemp = () => {
    axios
      .get(`${process.env.REACT_APP_API_NODE_URL}/graphics/temperature/1`)
      .then((res) => {
        setSystemGpuTemp(res.data);
      })
      .catch((err) => console.log(err));
  };

  const BitkubBalances = () => {
    axios
      .get(`${process.env.REACT_APP_API_PYTHON_URL}/bitkub/balances`)
      .then((res) => {
        setBitkubBalances(res.data);
      })
      .catch((err) => console.log(err));
  };

  const Crypto = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_PYTHON_URL}/binance/tickers`)
      .then((res) => {
        setCryptoMarket(res.data);
      })
      .catch((err) => console.log(err));
  };

  const BitkubTicker = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_PYTHON_URL}/bitkub/ticker`)
      .then((res) => {
        setCryptoMarket(res.data);
      })
      .catch((err) => console.log(err));
  };

  const BinanceBalances = () => {
    axios
      .get(`${process.env.REACT_APP_API_PYTHON_URL}/binance/balances`)
      .then((res) => {
        setBinanceBalances(res.data);
      })
      .catch((err) => console.log(err));
  };

  const Exchangerates = () => {
    axios
      .get(process.env.REACT_APP_API_RATE_URL)
      .then((res) => {
        setCryptoRates(Number(res.data.usd.thb));
      })
      .catch((err) => {
        setCryptoRates(32);
        console.log(err);
      });
  };

  const NanopoolChart = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_NANOPOOL_URL}/hashratechart/${process.env.REACT_APP_BITKUB_WALLET_ETH}`
      )
      .then((res) => {
        setMinerNanopoolChart(res.data);
      })
      .catch((err) => console.log(err));
  };

  const NanopoolUser = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_NANOPOOL_URL}/user/${process.env.REACT_APP_BITKUB_WALLET_ETH}`
      )
      .then((res) => {
        setMinerNanopoolUser(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  // const FlexpoolBalance = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_FLEXPOOL_URL}/miner/balance?coin=etc&address=${process.env.REACT_APP_BITKUB_WALLET_ETH}&countervalue=thb`
  //     )
  //     .then((res) => {
  //       if (_.isNil(res?.data?.error)) {
  //         setMinerFlexpoolBalance(res?.data?.result);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const FlexpoolWorkerCount = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_FLEXPOOL_URL}/miner/workerCount?coin=eth&address=${process.env.REACT_APP_BITKUB_WALLET_ETH}`
  //     )
  //     .then((res) => {
  //       if (_.isNil(res?.data?.error)) {
  //         setMinerFlexpoolWorkerCount(res?.data?.result);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const FlexpoolStats = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_FLEXPOOL_URL}/miner/stats?coin=eth&address=${process.env.REACT_APP_BITKUB_WALLET_ETH}`
  //     )
  //     .then((res) => {
  //       if (_.isNil(res?.data?.error)) {
  //         setMinerFlexpoolStats(res?.data?.result);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const FlexpoolChart = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_FLEXPOOL_URL}/miner/chart?coin=eth&address=${process.env.REACT_APP_BITKUB_WALLET_ETH}`
  //     )
  //     .then((res) => {
  //       if (_.isNil(res?.data?.error)) {
  //         setMinerFlexpoolChart(res?.data?.result);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const FlexpoolWorkers = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_FLEXPOOL_URL}/miner/workers?coin=eth&address=${process.env.REACT_APP_BITKUB_WALLET_ETH}`
  //     )
  //     .then((res) => {
  //       if (_.isNil(res?.data?.error)) {
  //         setMinerFlexpoolWorkers(res?.data?.result);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  const EthermineDashboard = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_ETHERMINE_URL}/miner/${process.env.REACT_APP_METAMASK_WALLET}/dashboard`
      )
      .then((res) => {
        setMinerEthermineDashboard(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const EtherminePayouts = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_ETHERMINE_URL}/miner/${process.env.REACT_APP_METAMASK_WALLET}/payouts`
      )
      .then((res) => {
        setMinerEtherminePayouts(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const Verus = () => {
    let getMiner = axios({
      method: "get",
      url: `${process.env.REACT_APP_API_PYTHON_URL}/verus/miner`,
    });
    const getMarket = axios({
      method: "get",
      url: `${process.env.REACT_APP_API_PYTHON_URL}/verus/market`,
    });
    axios
      .all([getMiner, getMarket])
      .then(
        axios.spread((...responses) => {
          let resMiner = responses[0];
          let resMarket = responses[1];
          if (
            _.isNil(resMiner?.data?.error) &&
            _.isNil(resMarket?.data?.error)
          ) {
            setVerus({ ...resMiner?.data, ...resMarket?.data });
          }
        })
      )
      .catch((err) => console.log(err));
  };

  // const Nicehash = async () => {
  //   await axios
  //     .get(`${process.env.REACT_APP_API_PYTHON_URL}/verus/miner`)
  //     .then((res) => {
  //       setVerus(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const Duinocoin = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_DUINOCOIN_URL}/users/${duinocoinAccount}?limit=5`
      )
      .then((res) => {
        setDuinocoin(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const DuinocoinStatistic = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_DUINOCOIN_URL}/api.json`)
      .then((res) => {
        setDuinocoinStatistic(res?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    Exchangerates();
    BitkubBalances();
    CpuInfo();
    GpuInfo();
    OsInfo();
    BaseBoard();
    MemLayout();
    // cloudflareDev();
  }, []);

  useEffect(() => {
    Crypto();
    BinanceBalances();
    EthermineDashboard();
    EtherminePayouts();
    NanopoolChart();
    NanopoolUser();
    DuinocoinStatistic();
    const interval = setInterval(() => {
      Crypto();
      BinanceBalances();
      EthermineDashboard();
      EtherminePayouts();
      NanopoolChart();
      NanopoolUser();
      DuinocoinStatistic();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    HiveOs();
    const interval = setInterval(() => {
      HiveOs();
    }, 7000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    CpuUsed();
    CpuTemperatures();
    GpuTemp();
    MemInfo();
    // FlexpoolBalance();
    // FlexpoolWorkerCount();
    // FlexpoolStats();
    // FlexpoolChart();
    // FlexpoolWorkers();
    Verus();
    Duinocoin();
    NetworkInfo();
    DiskInfo();
    setDate(moment().format("Do MMMM YYYY"));
    setTime(moment().format("HH:mm:ss"));
    const interval = setInterval(() => {
      CpuUsed();
      CpuTemperatures();
      GpuTemp();
      MemInfo();
      // FlexpoolBalance();
      // FlexpoolWorkerCount();
      // FlexpoolStats();
      // FlexpoolChart();
      // FlexpoolWorkers();
      Verus();
      Duinocoin();
      NetworkInfo();
      DiskInfo();
      setDate(moment().format("Do MMMM YYYY"));
      setTime(moment().format("HH:mm:ss"));
    }, 5000);
    return () => clearInterval(interval);
  }, [duinocoinAccount]);

  const handleChange = (event, newValue) => {
    scrollTop();
    setValue(newValue);
  };

  const handleChangeSwitch = (e) => {
    setDarkMode(e.target.checked);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.wrapper}>
        <Grid container alignItems="center" className="pt-3">
          <Grid item xs={6}>
            <Typography variant="h5">MY RIG</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Typography variant="body2">Dark Mode</Typography>
              <Switch
                color="primary"
                checked={darkMode}
                onChange={handleChangeSwitch}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Home />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Coin />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Rig />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Miner />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <System />
        </TabPanel>
      </Container>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        className={classes.appBar}
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab
          style={{ minHeight: 0 }}
          icon={<WorkIcon fontSize="small" />}
          label={<span style={{ fontSize: 11 }}>Home</span>}
          {...a11yProps(0)}
        />
        <Tab
          style={{ minHeight: 0 }}
          icon={<MonetizationOnIcon fontSize="small" />}
          label={<span style={{ fontSize: 11 }}>Coin</span>}
          {...a11yProps(1)}
        />
        <Tab
          style={{ minHeight: 0 }}
          icon={<StarIcon fontSize="small" />}
          label={<span style={{ fontSize: 11 }}>Rig</span>}
          {...a11yProps(2)}
        />
        <Tab
          style={{ minHeight: 0 }}
          icon={<EqualizerIcon fontSize="small" />}
          label={<span style={{ fontSize: 11 }}>Miner</span>}
          {...a11yProps(3)}
        />
        <Tab
          style={{ minHeight: 0 }}
          icon={<LaptopMacIcon fontSize="small" />}
          label={<span style={{ fontSize: 11 }}>Info</span>}
          {...a11yProps(4)}
        />
      </Tabs>
    </div>
  );
}
